import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import * as React from "react";
import TokenSelectionModal from "../Modals/TokenSelectionModal";
import useWindowDimensions from '../helpers/helper';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: 'Inter',
          fontWeight: 'normal',
          marginTop: '4px',
          fontSize: '25px',
        },
        notchedOutline: {
          borderColor: "#6578F4",
          opacity: '25%',
          vectorEffect: 'non-scaling-stroke',
          borderRadius: '5px'
        }

      },
    },
  },
});

export function TokenCard({
  tokenAValue,
  tokenBValue,
  setTokenAValue,
  setTokenBValue,
  tokenA,
  tokenB,
  setTokenA,
  setTokenB,
  setGraphsData,
  setTableData,
  setData1,
  setData2,
}) {
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = React.useState(false);
  const [queryParams, setQueryParams] = React.useState({
    sell_symbol: null,
    sell_ID: null,
    sell_amount: 0,
    buy_symbol: null,
    buy_ID: null,
  });
  const [tokenType, setTokenType] = React.useState("");
  const [routes, setRoutes] = React.useState([]);
  const [slippage, setSlippage] = React.useState(0);
  const [minAmountReceived, setMinAmountReceived] = React.useState(0);
  const [gasFee, setGasFee] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const findParameters = React.useCallback(() => {
    // console.log("routesroutesroutes", routes);
    setSlippage(routes[0][1].swap_0.price_impact);
    setGasFee(routes[0][1].gas_fee);
    // console.log("routes[0][1].price", routes[0][1].price);
    setPrice(routes[0][1].price)
    setMinAmountReceived(routes[0][1].amount_out);
    setTokenBValue(routes[0][1].amount_out);
  }, [setTokenBValue, routes]);

  React.useEffect(() => {
    if (tokenA && tokenB) {
      setQueryParams({
        sell_symbol: tokenA.symbol,
        sell_ID: tokenA.address.toLowerCase(),
        sell_amount: tokenAValue,
        buy_symbol: tokenB.symbol,
        buy_ID: tokenB.address.toLowerCase(),
      });
    }
  }, [tokenA, tokenB, tokenAValue]);

  React.useEffect(() => {
    if (routes.length !== 0) {
      findParameters();
      setIsLoading(false);
    }
  }, [routes, findParameters]);

  const callOrderRouter = async (queryParams) => {
    let url = `/order_router?sell_symbol=${queryParams.sell_symbol}&sell_ID=${queryParams.sell_ID}&sell_amount=${queryParams.sell_amount}&buy_symbol=${queryParams.buy_symbol}&buy_ID=${queryParams.buy_ID}`;
    axios
      .get(url)
      .then((response) => {
        setRoutes(response.data.routes);
        setTableData(response.data.routes);

        console.log("Response from API: ", response);

        let pathGraph = response.data.path_graph;
        let poolGraph = response.data.pool_graph;
        // setPathGraph(response.data.routes);
        // setPoolGraph(poolGraph);
        setGraphsData(response.data.routes, poolGraph);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  };

  const handleShowSelectionModal = () => {
    setShowModal(true);
  };

  const handleCloseSelectionModal = () => {
    setShowModal(false);
  };

  const handleOnSelectToken = (value) => {
    if (tokenType === "tokenA") {
      setTokenA(value);
      setSlippage(0);
      setMinAmountReceived(0);
      setGasFee(0);
      setTokenAValue(0);
      setTokenBValue(0);
    } else if (tokenType === "tokenB") {
      setTokenB(value);
    }
    handleCloseSelectionModal();
  };

  const onClickBestPriceButton = (event) => {
    event.preventDefault();
    if (tokenAValue === 0 || tokenAValue === "0" || tokenAValue === undefined || tokenAValue === "undefined" || tokenAValue === null || tokenAValue === "null") {
      let variant = "error";
      enqueueSnackbar("Enter value for selling token", { variant });
    }
    else if (tokenA === null || tokenA === "null" || tokenA === undefined || tokenA === "undefined") {
      let variant = "error";
      enqueueSnackbar("Select selling token", { variant });
    }
    else if (tokenB === null || tokenB === "null" || tokenB === undefined || tokenB === "undefined") {
      let variant = "error";
      enqueueSnackbar("Select purchasing token", { variant });
    }
    else {
      setIsLoading(true);
      setData1();
      setData2();
      setSlippage(0);
      setMinAmountReceived(0);
      setGasFee(0);
      setTokenBValue(0);
      callOrderRouter(queryParams);
    }

  }

  return (
    <div>
      <Card
        sx={{
          maxWidth: 450,
          padding: 4,
          borderRadius: '10px'
        }}
        style={{
          background: 'linear-gradient(180deg, #FEFEFE 0%, rgba(232, 247, 255, 0.596358) 27.86%, rgba(243, 224, 255, 0) 100%)',
          border: '0.6px solid #D0DDFF',
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))"
        }}
        elevation={0}
      >
        <h5 style={{ fontFamily: 'Inter', fontWeight: 'normal', marginBottom: '40px' }}>
          Exchange
        </h5>
        <br></br>
        <span
          style={{
            fontSize: '11px',
            fontFamily: 'Inter'
          }}
        >
          You Sell
        </span>
        <ThemeProvider theme={theme}>
          <TextField
            style={{
              fontWeight: 'lighter',
              borderRadius: '5px',
              background: '#FFFFFF',
            }}
            sx={{
              borderRadius: '5px',
              marginTop: '5px',
              '& fieldset': {
              },
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right",
                }
              }
            }}
            fullWidth
            size="small"
            type="number"
            value={tokenAValue}
            onChange={(e) => {
              setTokenAValue(e.target.value);
              setSlippage(0);
              setMinAmountReceived(0);
              setGasFee(0);
              setTokenBValue(0);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    setTokenType("tokenA");
                    handleShowSelectionModal();
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ borderRadius: "10px", width: tokenA !== null ? "100px" : "", marginTop: tokenA !== null ? "5px" : "" }}
                    sx={{ borderColor: '#BEBEBE', border: 'none', "&:hover": { backgroundColor: "transparent", border: 'none' } }}
                  >
                    {tokenA !== null ? (
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            height="20px"
                            style={{
                              float: "left",
                              marginTop: "2px",
                              marginBottom: "0px",
                              marginRight: "0px",
                            }}
                            src={tokenA?.logoURI}
                            alt="token1"
                          />
                        </Grid>
                        <Grid item xs={9} style={{
                          float: "right",
                          marginLeft: '0px'
                        }}>
                          <span
                            style={{
                              fontFamily: "Inter",
                              color: 'black',
                            }}
                          >
                            <span style={{
                              fontSize: "15px",
                              fontWeight: 'normal',
                              marginRight: '4px',
                              paddingLeft:'5px'
                            }}>
                              {tokenA?.symbol}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="25" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 14 20"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        color="black"
                        style={{ margin: "8px 10px 5px 0px", fontSize: '14px' }}
                        gutterBottom

                      >
                        Select a Token{" "}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 14 20"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
                      </Typography>
                    )}
                  </Button>
                </InputAdornment>
              ),
              style: { fontSize: 30 },
            }}
          />
        </ThemeProvider>
        {/* </Paper> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <SwapVertIcon fontSize="medium" style={{ color: 'grey' }} />
        </div>
        <span
          style={{
            fontSize: '11px'
          }}
        >
          You Buy
        </span>
        <ThemeProvider theme={theme}>
          <TextField
            inputProps={{
              readOnly: Boolean(true),
              disabled: Boolean(true),
            }}
            style={{
              fontWeight: 'lighter',
              borderRadius: '5px',
              background: '#FFFFFF'
            }}
            sx={{
              marginTop: '5px',
              '& fieldset': {
              },
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right"
                }
              }
            }}
            size="small"
            fullWidth
            type="number"
            value={tokenBValue}
            onChange={(e) => {
              setTokenBValue(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    setTokenType("tokenB");
                    handleShowSelectionModal();
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ borderRadius: "10px", width: tokenB !== null ? "100px" : "", marginTop: tokenB !== null ? "5px" : "" }}
                    sx={{ borderColor: '#BEBEBE', border: 'none', "&:hover": { backgroundColor: "transparent", border: 'none' } }}
                  >
                    {tokenB !== null ? (
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            height="20px"
                            style={{
                              float: "left",
                              marginTop: "2px",
                              marginBottom: "0px",
                              marginRight: "0px",
                            }}
                            src={tokenB?.logoURI}
                            alt="token2"
                          />
                        </Grid>
                        <Grid item xs={9} style={{
                          float: "right",
                          marginLeft: '0px'
                        }}>
                          <span
                            style={{
                              fontFamily: "Inter",
                              color: 'black',
                            }}
                          >
                            <span style={{
                              fontSize: "15px",
                              fontWeight: 'normal',
                              marginRight: '4px',
                              paddingLeft:'5px'
                            }}>
                              {tokenB?.symbol}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="25" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 14 20"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        color="black"
                        style={{ margin: "8px 10px 5px 0px", fontSize: '14px' }}
                        gutterBottom

                      >
                        Select a Token{" "}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 14 20"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
                      </Typography>
                    )}
                  </Button>
                </InputAdornment>
              ),
              style: { fontSize: 30 },
            }}
          />
        </ThemeProvider>
        {/* </Paper> */}
        <div style={{ marginBottom: "50px" }}>
          {isLoading ? (
            <Button
              style={{
                marginTop: "40px",
                borderRadius: "7px",
                height: "48px",
                backgroundColor: '#354BF0',
                textTransform: 'unset'
              }}
              fullWidth
              variant="contained"
              size="large"
              onClick={(event) => {
                let variant = "error";
                enqueueSnackbar("Fetching is in progress", { variant });
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: "Inter",
                  fontSize: width < 337 ? "10px" : width < 390 ? "12px" : "15px",
                }}
              >
                <CircularProgress color="inherit" size={28} style={{ margin: width < 337 ? '8px' : '10px' }} /> Fetching Best Price ...
              </span>
            </Button>
          ) : (
            <Button
              style={{
                marginTop: "40px",
                borderRadius: "7px",
                height: "48px",
                backgroundColor: '#354BF0',
                textTransform: 'unset'
              }}
              fullWidth
              variant="contained"
              size="large"
              onClick={(event) => onClickBestPriceButton(event)}
            >
              <span style={{ fontSize: "15px", fontWeight: 'normal' }}>
                Find best price
              </span>
            </Button>
          )}
        </div>

        <Grid
          style={{
            fontFamily: 'Inter',
            fontSize: "12px",
          }}
          container
        // spacing={2}
        >
          <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 'bold' }}>
              Minimum Received
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 'bold' }}>
              {tokenB?.symbol} {parseFloat(minAmountReceived).toFixed(5)}
            </span>
          </Grid>
          <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Price Impact
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              {" "}
              {parseFloat(slippage).toFixed(5)} {"%"}
            </span>
          </Grid>
          <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Gas fees
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              {parseFloat(gasFee).toFixed(5)} (ETH)
            </span>
          </Grid>
          {/* <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Price Impact
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Low
            </span>
          </Grid> */}
        </Grid>
      </Card>
      <TokenSelectionModal
        show={showModal}
        handleClose={handleCloseSelectionModal}
        selectToken={handleOnSelectToken}
        // tokenType={tokenType}
        selectedToken={tokenType === "tokenA" ? tokenB : tokenType === "tokenB" ? tokenA : null}
      />
    </div>
  );
}
