import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import "../../App.css";
import ResponsiveAppBar from "../../Components/Headers/header";
import { TokenCard } from "../../Components/TokenCard/card";
import HomeBanner from "../../Components/Banner/HomeBanner";
import BestRoutes from "../../Components/Graphs/BestRoutes";
import PossibleRoutes from "../../Components/Graphs/PossibleRoutes";
import RouteDetailTable from "../../Components/Table/RouteDetailTable";
import Home from "../Pages/Home";

function App() {
  return <Home />;
}

export default App;
