import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { tokens } from "../assets/data/tokens";

const TokenSelectionModal = (props) => {
  const [searchedItem, setSearchedItem] = useState("");

  return (
    <div>
      <Modal show={props.show} onHide={props.handleClose} scrollable={true}>
        <ModalHeader closeButton>
          <ModalTitle>Select a token</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <TextField
              fullWidth
              input="string"
              value={searchedItem}
              placeholder="Search Token"
              onChange={(e) => setSearchedItem(e.target.value)}
            />
            <List component="nav">
              {tokens
                .filter((val) => {
                  if (searchedItem == "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(searchedItem.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.symbol
                      .toLowerCase()
                      .includes(searchedItem.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((token, index) => (
                  <div key={index}>
                    <Button
                      fullWidth
                      onClick={() => props.selectToken(token)}
                      style={{ justifyContent: "flex-start" }}
                      disabled={
                        props.selectedToken === null
                          ? false
                          : props.selectedToken.symbol === token.symbol
                            ? true
                            : false
                      }
                    >
                      <img
                        height="30px"
                        style={{
                          float: "left",
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginRight: "10px",
                        }}
                        src={token.logoURI}
                        alt="token1"
                      />
                      <Typography
                        variant="body"
                        color={
                          props.selectedToken === null
                            ? "black"
                            : props.selectedToken.symbol === token.symbol
                              ? "grey"
                              : "black"
                        }
                        style={{ fontWeight: '700', marginRight: '10px', float: 'right' }}
                      >
                        {token.name}
                      </Typography>
                      <Typography
                        variant="body"
                        color={props.selectedToken === null ? "grey" : props.selectedToken.symbol === token.symbol ? "grey" : "black"}
                        style={{ fontWeight: '400', }}
                      >
                        {token.symbol}
                      </Typography>
                    </Button>
                  </div>
                ))}
            </List>
          </Box>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TokenSelectionModal;
