import * as React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import useWindowDimensions from '../helpers/helper';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import { Markup } from 'interweave';
import Table from 'react-bootstrap/Table';
import { stylesheet1 } from '../Graphs/GraphHelper/Styles';
function RouteDetailTable(props) {

    return (
        <Table responsive style={{ marginBottom: '30px', marginTop: '40px' }}>
            <thead>
                <tr
                    style={{
                        background: "#FFFFFF",
                        border: "0.6px solid #E5E5E5",
                        borderRadius: "3px",
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontFamily: 'Inter',
                        height: '40px'
                    }}
                >
                    <th colSpan={1}></th>
                    <th style={{ fontWeight: 400 }}>Best Routes</th>
                    <th style={{ fontWeight: 400 }}>Route</th>
                    {/* <th style={{ fontWeight: 400 }}>Price Impact</th> */}
                    <th style={{ fontWeight: 400 }}>Gas Fee</th>
                    <th style={{ fontWeight: 400 }}>Best Price</th>
                    <th style={{ fontWeight: 400 }}>Min Receive</th>
                    <th style={{ fontWeight: 400 }}>Price Impact</th>
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((row, index) => (
                    <React.Fragment key={index} >
                        <tr style={{ fontFamily: 'Inter', fontSize: '10px', height: '50px' }}>
                            <td> <div
                                style={{ marginTop: '10px' }}>{index + 1}
                            </div></td>
                            <td colSpan={1}>
                                {/* {data1.map((path, index) => ( */}
                                <div style={{ justifyContent: 'left' }} >
                                    <div
                                        style={{ marginTop: '0px', height: "30px", justifyContent: 'left', display: 'flex', alignItems: 'start', }}
                                    >
                                        <CytoscapeComponent
                                            elements={props.data1[index]?.nodes}
                                            // cytoscapeOptions={{ wheelSensitivity: 0.1 }}
                                            style={{ width: '90px', height: '30px', display: 'flex', alignItems: 'start', marginLeft: '-20px' }}
                                            layout={{ name: 'grid' }}
                                            stylesheet={stylesheet1}
                                            // zoom={0}
                                            panningEnabled={false}
                                            userPanningEnabled={false}
                                        // zoomingEnabled={true}
                                        />
                                    </div>
                                </div>
                                {/* ))} */}
                            </td>
                            <td style={{ paddingTop: '15px' }} onClick={() => { props.handleClick(index) }}>
                                <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            {props.swapData[index]}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {props.tableData[index]?.expand ? (<ExpandLessIcon fontSize="small" />) : (<ExpandMoreIcon fontSize="small" />)}
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </td>
                            {/* <td style={{ paddingTop: '15px' }}>Low</td> */}
                            <td style={{ paddingTop: '15px' }}>{parseFloat(row[1].gas_fee).toFixed(4)}</td>
                            <td style={{ paddingTop: '15px' }}>{parseFloat(row[1].price).toFixed(4)}</td>
                            <td style={{ fontWeight: 'bold', paddingTop: '15px' }}>{parseFloat(row[1].amount_out).toFixed(5)}  {props.tokenB?.symbol}</td>
                            <td style={{ paddingTop: '15px' }}>{parseFloat(row[1].swap_0.price_impact).toFixed(4)} {"%"}</td>
                        </tr>
                        {props.tableData[index].expand ? (
                            <tr>
                                <td colSpan={2}></td>
                                <td colSpan={6}>
                                    <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>
                                        <Markup content={props.tableData[index][1].swap_0.description1} />
                                    </Typography>
                                    {props.tableData[index][1]?.swap_1 ? (
                                        <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>
                                            <Markup content={props.tableData[index][1].swap_1?.description1} />
                                        </Typography>
                                    ) : (null)}
                                    {props.tableData[index][1]?.swap_2 ? (
                                        <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>
                                            <Markup content={props.tableData[index][1].swap_2?.description1} />
                                        </Typography>
                                    ) : (null)}
                                    {props.tableData[index][1]?.swap_3 ? (
                                        <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>
                                            <Markup content={props.tableData[index][1].swap_3?.description1} />
                                        </Typography>
                                    ) : (null)}
                                </td>
                            </tr>
                        ) : (null)}

                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    );
}
export default RouteDetailTable;
