import * as React from 'react';
import useWindowDimensions from '../helpers/helper';
import CytoscapeComponent from 'react-cytoscapejs';
import { stylesheet, stylesheet1 } from './GraphHelper/Styles';
import { Box, Container } from '@mui/material';
import Slider from '@mui/material/Slider';
function BestRoutes(props) {

    const { height, width } = useWindowDimensions();
    const [graphZoom, setGraphZoom] = React.useState(0.3);
    return (
        <Container
            style={{
                // height: "450px",
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // flexDirection: 'column'
            }}
        >
            <h6
                style={{
                    // textAlign: 'center', 
                    fontWeight: 'normal',
                    fontFamily: 'Inter',
                    marginTop: '20px'
                }}
            >
                Best Routes
            </h6>
            {props.data1.slice(0, 3).map((path, index) => (
                <div key={index} style={{ justifyContent: 'left' }} >
                    <div style={{ lineHeight: 1, paddingBottom: '10px' }}>
                        <div
                            style={{ height: "40px", justifyContent: 'left', display: 'flex', alignItems: 'start', position: 'relative', paddingLeft: '0px', }}
                        >
                            <CytoscapeComponent
                                elements={path.nodes}
                                style={{ width: '100px', height: '50px', display: 'flex', marginLeft: '-20px' }}
                                layout={{ name: 'grid' }}
                                stylesheet={stylesheet1}
                                panningEnabled={false}
                                userPanningEnabled={false}
                            />
                        </div>
                        <span
                            style={{
                                fontWeight: 'bold',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                            }}
                        >
                            {props.tableData[index][1]?.amount_out.toFixed(2)} {" "} {props.tokenB?.symbol}
                        </span>
                        <br />
                        <span
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '10px',
                                fontWeight: 'normal',
                                color: '#323232'
                            }}
                        >
                            {props.swapData[index]}
                        </span>
                        <br />
                        <span
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '10px',
                                fontWeight: 'normal',
                                color: '#323232'
                            }}
                        >
                            Price Imapct (-{props.tableData[index][1]?.swap_0.price_impact.toFixed(2)}{"%"})
                        </span>
                    </div>
                    {index < 2 ? (<hr />) : (null)}

                </div>
            ))}
        </Container>
    );
}
export default BestRoutes;
